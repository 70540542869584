// Default imports:
import "../styles/main.scss";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import imagesLoaded from "imagesloaded";

export const mm = gsap.matchMedia();

gsap.registerPlugin(ScrollTrigger, CustomEase);

gsap.defaults({ ease: "ease" });
gsap.config({
    nullTargetWarn: false,
    force3D: true,
    autoSleep: 60
});

CustomEase.create("slider-ease", "0.26, 0, 0.38, 1");

function init(){
    (async function (){
        const module = await import("./init-scripts");
        if (module.default) module.default();
    })();

    const main = document.querySelector<HTMLElement>("main.main");
    if (!main) return;


    checkRunLoader(main);
    storeUTMs();
}


function checkRunLoader(main: HTMLElement){
    const masterLoaderEnabled = true;

    if (masterLoaderEnabled && localStorage.getItem("shownLoader") !== "true"){
        imagesLoaded(main, async function (){
            const module = await import("./base/loader");
            if (module.default) module.default();
        });

        localStorage.setItem("shownLoader", "true");
    } else gsap.set(".loader", { display: "none" });
}

init();

function storeUTMs(){
    if (window.location.search.includes("utm_")){
        document.addEventListener("click", function(event){
            const aTag = (event.target as HTMLElement).closest("a");

            if (aTag !== null){
                const urlParams = new URLSearchParams(window.location.search);
                const aParams = new URLSearchParams(aTag.search);
    
                for (const [key, value] of urlParams.entries()){
                    if (! aParams.has(key) && key.includes("utm_")){
                        aParams.append(key, value);
                    }
                }

                aTag.href = aTag.href.split("?")[0] + "?" + aParams.toString();
            }
        });
    }

    const formUrlField = document.querySelector("input[name=\"formurl\"]");
    const currentPageUrl = window.location.href;
    if (formUrlField) formUrlField.setAttribute("value", currentPageUrl);
}